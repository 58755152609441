var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CModal',{attrs:{"show":_vm.$store.state.showModal,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2","size":"xl","color":"dark"},on:{"update:show":function($event){return _vm.$set(_vm.$store.state, "showModal", $event)}},scopedSlots:_vm._u([(_vm.form)?{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title font-weight-bold"},[_vm._v(" تعديل الاجابة رقم : "+_vm._s(_vm.form.id)+" ")]),_c('CButtonClose',{staticClass:"text-white",on:{"click":_vm.closeModal}})]},proxy:true}:null,{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":_vm.closeModal}},[_vm._v("الغاء")]),_c('CButton',{attrs:{"disabled":_vm.loading,"type":"button","color":"success"},on:{"click":_vm.update}},[(_vm.loading)?_c('CSpinner',{attrs:{"color":"white","size":"sm"}}):_c('span',[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" حفظ ")],1)],1)]},proxy:true}],null,true)},[(_vm.form)?_c('CRow',[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',[_c('CCardBody',[_c('CForm',[_c('CRow',{staticClass:"mt-3 mb-5"},[_c('CCol',{attrs:{"sm":"12","md":"2"}},[_vm._v(" محتوى الاجابة ")]),_c('CCol',{attrs:{"sm":"12","md":"9"}},[_c('editor',{attrs:{"apiKey":"mln1amd9gx9droz3rcanvhm9dflaur4dv5zn8udyub03yjph","placeholder":"اكتب المحتوى هنا","init":{
                      height: 300,
                      menubar: false,
                      image_uploadtab: true,
                      images_upload_url: ("" + _vm.$tinyUploadImage),
                      plugins: [
                        'advlist autolink lists link image charmap',
                        'searchreplace visualblocks code fullscreen',
                        'print preview anchor insertdatetime media',
                        'paste code help wordcount table directionality emoticons' ],
                      toolbar:
                        'emoticons | undo redo image | formatselect | table | link charmap searchreplace visualblocks fullscreen anchor media paste | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | ltr rtl | help',
                    }},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.description"}}),(_vm.$v.form.description.$anyError)?_c('h6',{staticClass:"text-danger mt-2"},[_vm._v(" يوجد خطأ في هذا الحقل ")]):_vm._e()],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }